import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import throttle from "lodash/throttle"

import Buttons from "../../buttons/buttons"
import RightFrame from "./icon/rightFrame"
import LeftFrame from "./icon/leftFrame"

import * as styles from "./homeHero.module.scss"

const formatTextWithLineBreaks = text => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={String(index)}>
      {line}
      <br />
    </React.Fragment>
  ))
}

const HomeHero = ({ angle, startColor, endColor, title, subtitle, links, isWhiteBackground }) => {
  const [hasScrolled, setHasScrolled] = React.useState(false)

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 0 && !hasScrolled) {
        setHasScrolled(true)
      }
    }, 500)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [hasScrolled])

  const sectionClass = cx(styles.homeHero, {
    [styles.whiteBackground]: isWhiteBackground,
  })

  const gradientStyle = {
    backgroundImage: `linear-gradient(${angle ?? 90}deg, ${startColor ?? "#ED3175"} , ${endColor ?? "#FF7F41"})`,
  }

  return (
    <section className={sectionClass} style={isWhiteBackground ? {} : gradientStyle}>
      <div className={styles.content}>
        <LeftFrame isUserScroll={hasScrolled} />
        <h1>{title}</h1>
        <p>{formatTextWithLineBreaks(subtitle)}</p>
        <Buttons buttons={links} className={styles.btnContainer} />
        <RightFrame isUserScroll={hasScrolled} />
      </div>
    </section>
  )
}

HomeHero.propTypes = {
  angle: PropTypes.number,
  startColor: PropTypes.string,
  endColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isWhiteBackground: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      buttonType: PropTypes.string,
      label: PropTypes.string.isRequired,
      linkPosition: PropTypes.string,
      newTab: PropTypes.bool,
      noFollow: PropTypes.bool,
      size: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
}

HomeHero.defaultProps = {
  angle: 90,
  startColor: "#ED3175",
  endColor: "#FF7F41",
  links: [],
  isWhiteBackground: false,
}

export default HomeHero
