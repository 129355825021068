import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import R_Group_1 from "../../../../../assets/svgs/R_Group_1.svg"
import R_Group_2 from "../../../../../assets/svgs/R_Group_2.svg"
import R_Group_3 from "../../../../../assets/svgs/R_Group_3.svg"
import R_Group_4 from "../../../../../assets/svgs/R_Group_4.svg"
import R_Group_5 from "../../../../../assets/svgs/R_Group_5.svg"
import R_Group_6 from "../../../../../assets/svgs/R_Group_6.svg"
import R_Group_7 from "../../../../../assets/svgs/R_Group_7.svg"
import R_Group_8 from "../../../../../assets/svgs/R_Group_8.svg"
import R_Group_9 from "../../../../../assets/svgs/R_Group_9.svg"
import decorativeImage from "../../../../../assets/images/icons-right.png"

import * as styles from "./icon.module.scss"

const RightFrame = ({ isUserScroll }) => {
  const classForDecorateRight = classNames(styles.decorateRight, {
    [styles.zoomIn]: isUserScroll,
  })

  return (
    <div className={styles.rightFrameContainer}>
      <R_Group_1 className={styles.rGroup1} />
      <R_Group_2 className={styles.rGroup2} />
      <R_Group_3 className={styles.rGroup3} />
      <R_Group_4 className={styles.rGroup4} />
      <R_Group_5 className={styles.rGroup5} />
      <R_Group_6 className={styles.rGroup6} />
      <R_Group_7 className={styles.rGroup7} />
      <R_Group_8 className={styles.rGroup8} />
      <R_Group_9 className={styles.rGroup9} />
      <div className={classForDecorateRight}>
        <img src={decorativeImage} alt="Decorative" loading="lazy" />
      </div>
    </div>
  )
}

export default RightFrame

RightFrame.propTypes = {
  isUserScroll: PropTypes.bool.isRequired,
}
