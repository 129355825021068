// extracted by mini-css-extract-plugin
export var sbShowMain = "icon-module--sb-show-main--30eam";
export var zoomIn = "icon-module--zoomIn--1Vr1U";
export var rightFrameContainer = "icon-module--rightFrameContainer--3Fvsg";
export var rGroup1 = "icon-module--rGroup1--1XPMg";
export var fadeInUp = "icon-module--fadeInUp--3R5MM";
export var rGroup2 = "icon-module--rGroup2--3HLdH";
export var rGroup3 = "icon-module--rGroup3--2CZsS";
export var rGroup4 = "icon-module--rGroup4--jgyoh";
export var rGroup5 = "icon-module--rGroup5--17IFZ";
export var rGroup6 = "icon-module--rGroup6--Aaann";
export var rGroup7 = "icon-module--rGroup7--9QHIQ";
export var rGroup8 = "icon-module--rGroup8--24hg5";
export var rGroup9 = "icon-module--rGroup9--2g5F_";
export var decorateRight = "icon-module--decorateRight--2YCxo";
export var leftFrameContainer = "icon-module--leftFrameContainer--yzwBo";
export var lGroup1 = "icon-module--lGroup1--2pSD5";
export var lGroup2 = "icon-module--lGroup2--1syDb";
export var lGroup3 = "icon-module--lGroup3--1HRMS";
export var lGroup4 = "icon-module--lGroup4--jM72v";
export var lGroup5 = "icon-module--lGroup5--2_h-r";
export var lGroup6 = "icon-module--lGroup6--3Cfo1";
export var lGroup7 = "icon-module--lGroup7--2hi9p";
export var lGroup8 = "icon-module--lGroup8--26bpt";
export var lGroup9 = "icon-module--lGroup9--3T04b";
export var decorateLeft = "icon-module--decorateLeft--MgjV7";