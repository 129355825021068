import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import L_Group_1 from "../../../../../assets/svgs/L_Group_1.svg"
import L_Group_2 from "../../../../../assets/svgs/L_Group_2.svg"
import L_Group_3 from "../../../../../assets/svgs/L_Group_3.svg"
import L_Group_4 from "../../../../../assets/svgs/L_Group_4.svg"
import L_Group_5 from "../../../../../assets/svgs/L_Group_5.svg"
import L_Group_6 from "../../../../../assets/svgs/L_Group_6.svg"
import L_Group_7 from "../../../../../assets/svgs/L_Group_7.svg"
import L_Group_8 from "../../../../../assets/svgs/L_Group_8.svg"
import L_Group_9 from "../../../../../assets/svgs/L_Group_9.svg"
import decorativeImage from "../../../../../assets/images/icons-left.png"

import * as styles from "./icon.module.scss"

const LeftFrame = ({ isUserScroll }) => {
  const classForDecorateLeft = classNames(styles.decorateLeft, {
    [styles.zoomIn]: isUserScroll,
  })

  return (
    <div className={styles.leftFrameContainer}>
      <L_Group_1 className={styles.lGroup1} />
      <L_Group_2 className={styles.lGroup2} />
      <L_Group_3 className={styles.lGroup3} />
      <L_Group_4 className={styles.lGroup4} />
      <L_Group_5 className={styles.lGroup5} />
      <L_Group_6 className={styles.lGroup6} />
      <L_Group_7 className={styles.lGroup7} />
      <L_Group_8 className={styles.lGroup8} />
      <L_Group_9 className={styles.lGroup9} />
      <div className={classForDecorateLeft}>
        <img src={decorativeImage} alt="Decorative" loading="lazy" />
      </div>
    </div>
  )
}

export default LeftFrame

LeftFrame.propTypes = {
  isUserScroll: PropTypes.bool.isRequired,
}
